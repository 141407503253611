import React, { useContext, useRef, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import "../../styles/header.css";

const navLinks = [
    { path: "/home", display: "Home" },
    { path: "/about", display: "About" },
    { path: "/cars", display: "Cars" },
    { path: "/contact", display: "Contact" }
];

const Header = () => {
    const { user, logout } = useContext(AuthContext);
    const menuRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            navigate(`/cars?model=${searchQuery}`);
        }
    };

    return (
        <header className="header">
            <div className="header__top">
                <Container>
                    <Row>
                        <Col lg="6" md="6" sm="6">
                            <div className="header__top__left">
                                <span>Need Help?</span>
                                <span className="header__top__help">
                                    <i className="ri-phone-fill"></i> +90-555-555-5555
                                </span>
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="6">
                            <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                                {user ? (
                                    <>
                                        <span>Welcome, {user.email}</span>
                                        <button onClick={logout} className="btn btn-link">Logout</button>
                                    </>
                                ) : (
                                    <>
                                        <Link to="/Login" className=" d-flex align-items-center gap-1">
                                            <i className="ri-login-circle-line"></i> Login
                                        </Link>
                                        <Link to="/Register" className=" d-flex align-items-center gap-1">
                                            <i className="ri-user-line"></i> Register
                                        </Link>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="header__middle">
                <Container>
                    <Row>
                        <Col lg="4" md="3" sm="4">
                            <div className="logo">
                                <h1>
                                    <Link to="/home" className=" d-flex align-items-center gap-2">
                                        <i className="ri-car-line"></i>
                                        <span>Rent Car <br /> Service</span>
                                    </Link>
                                </h1>
                            </div>
                        </Col>

                        <Col lg="3" md="3" sm="4">
                            <div className="header__location d-flex align-items-center gap-2">
                                <span>
                                    <i className="ri-earth-line"></i>
                                </span>
                                <div className="header__location-content">
                                    <h4>Turkey</h4>
                                    <h6>Istanbul, Turkey</h6>
                                </div>
                            </div>
                        </Col>

                        <Col lg="3" md="3" sm="4">
                            <div className="header__location d-flex align-items-center gap-2">
                                <span>
                                    <i className="ri-time-line"></i>
                                </span>
                                <div className="header__location-content">
                                    <h4>Sunday to Friday</h4>
                                    <h6>08:00 - 17:00</h6>
                                </div>
                            </div>
                        </Col>

                        <Col lg="2" md="3" sm="0" className=" d-flex align-items-center justify-content-end ">
                            <button className="header__btn btn ">
                                <Link to="/contact">
                                    <i className="ri-phone-line"></i> Request a call
                                </Link>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="main__navbar">
                <Container>
                    <div className="navigation__wrapper d-flex align-items-center justify-content-between">
                        <span className="mobile__menu">
                            <i className="ri-menu-line" onClick={toggleMenu}></i>
                        </span>
                        <div className="navigation" ref={menuRef} onClick={toggleMenu}>
                            <div className="menu">
                                {navLinks.map((item, index) => (
                                    <NavLink
                                        to={item.path}
                                        className={(navClass) =>
                                            navClass.isActive ? "nav__active nav__item" : "nav__item"
                                        }
                                        key={index}
                                    >
                                        {item.display}
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                        <div className="nav__right">
                            <div className="search__box">
                                <form onSubmit={handleSearch}>
                                    <input 
                                        type="text" 
                                        placeholder="Search"
                                        value={searchQuery}
                                        style={{cursor: "text"}}
                                        onChange={(e) => setSearchQuery(e.target.value)} 
                                    />
                                    <button type="submit" style={{outline: "none", backgroundColor: "transparent", border: "none", color: "white"}}>
                                        <i className="ri-search-line"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </header>
    );
};

export default Header;
