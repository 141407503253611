import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosInstance';
import CreateCatalog from './CreateCatalog';
import CreateCar from './CreateCar';
import CreateModel from './CreateModel';
import CreateBrand from './CreateBrand';
import axios from 'axios';

function CreateModelPage() {
    const navigate = useNavigate();
    const [inputData, setInputData] = useState({
        model: {
            name: '',
            imageUrl: '',
            fuelType: 0,
            gearType: 0
        },
        brand: {
            name: '',
            brandId: 0
        }
    });

    const [feedbackMessage, setFeedbackMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const model = {
            name: inputData.model.name,
            imageUrl: inputData.model.imageUrl,
            fuelType: inputData.model.fuelType,
            gearType: inputData.model.gearType,
            brandId: inputData.brand.brandId
        };
        console.log(model);
        axios.post("https://carrentalservice.com.tr/api/model/add", model)
            .then(data => {
                console.log(data.data);
                setFeedbackMessage('Model successfully created');
            })
            .catch(err => console.log(err));
    };

    const handleChange = (e) => {
        const { name, value, dataset } = e.target;
        setInputData(prevState => ({
            ...prevState,
            [dataset.type]: {
                ...prevState[dataset.type],
                [name]: value
            }
        }));
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Create Forms</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <h4>Create Model</h4>
                    <CreateModel inputData={inputData.model} handleChange={handleChange} />
                </div>
                <div className="text-center">
                    <button type="submit" className="btn btn-primary">Create Model</button>
                </div>
                {feedbackMessage && (
                    <div className="alert alert-success mt-3" role="alert">
                        {feedbackMessage}
                    </div>
                )}
            </form>
        </div>
    );
}

export default CreateModelPage;
